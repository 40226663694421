<template>
  <div class="merchantScreenDetail">
    <div class="detail-wrapper">
        <div class="left">
        <div class="item">
            <span>店铺编号：</span>
            <span>{{ info.shopCode }}</span>
        </div>
        <div class="item">
            <span>处理店铺：</span>
            <span>{{ info.shopName }}</span>
        </div>
        <div class="item">
            <span>提交人：</span>
            <span>{{ info.createUser }}</span>
        </div>
        <!-- <div class="item">
            <span>提交日期：</span>
            <span>{{ info.createTime }}</span>
        </div> -->
        <!-- <div class="item">
            <span>状态：</span>
            <span :style="{ color: statusMapName[info.status].color }">{{ statusMapName[info.status].name }}</span>
        </div> -->
        <div class="item">照片/视频：</div>
        <div class="medias" v-if="info.screenShopResources && info.screenShopResources.length">
          <div class="media_wrap" v-for="item in info.screenShopResources" :key="item.id" @click="previewFun(item)">
            <img class="meida_box" :src="item.thumbnail" alt="照片">
            <img class="video_tip" v-if="item.type === 2" src="https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/video.png" alt="">
          </div>
        </div>
        <div  class="wordfile" v-if="viedoUrl" @click="closeViedo">
          <div @click.stop="closeViedo" style="position:absolute;top: 6%;right: 7%;font-size: 40px;cursor: pointer;"><i class="el-icon-error"></i></div>
          <div style="width:80%;height:80%;margin-left: 10%;margin-top: 6%;background: #fff;">
            <video width="100%" height="100%" controls autoplay :src="viedoUrl">
            </video>
          </div>
        </div>
        </div>
        <div class="right">
          <OrderFlow :repairNodes="orderFlow"/>
        </div>
    </div>
    <div class="rowBtn">
      <el-button size="medium" @click="cancelFun">取消</el-button>
      <el-button type="danger" size="medium" @click="rejectFun" v-if="info.status === 2 && !type">驳回</el-button>
      <el-button type="primary" size="medium" @click="agreeFun" v-if="info.status === 2 && !type">通过</el-button>
    </div>
    <el-dialog
        title="驳回理由"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="closeFun">
          <el-input type="textarea" :rows="5" maxlength="100" show-word-limit placeholder="请输入驳回理由" v-model="reason"></el-input>
        <div slot="footer">
            <el-button @click="closeFun">取 消</el-button>
            <el-button type="primary" @click="confirmFun">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
const statusMapName = {
  1: {
    name: '已撤销',
    color: 'red',
  },
  2: {
    name: '待审核',
    color: '#5b69fe'
  },
  3: {
    name: '已驳回',
    color: '#ec808d'
  },
  4: {
    name: '已通过',
    color: '#48e298'
  }
}
import OrderFlow from '@/pages/propertyModule/components/order-flow.vue'
export default {
  name: 'merchantScreenDetail',
  components: { OrderFlow },
  data () {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      info: {
        status: 1
      },
      statusMapName,
      medias: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ],
      orderFlow: [], // 工单流程
      dialogVisible: false,
      reason: '',
      viedoUrl: '', // 当前预览的视频地址
    }
  },
  created () {
    this.getDetail()
    this.getNodeDetail()
  },
  methods: {
    cancelFun () {
      this.$router.push({ path: '/checkModule/check/merchant' })
    },
    rejectFun () {
      this.dialogVisible = true
    },
    agreeFun () {
      this.$api.checkapi.agreeMerchantScreenApi({ id: this.id }).then(() => {
        this.actionAfterFun()
      })
    },
    closeFun () {
      this.reason = ''
      this.dialogVisible = false
    },
    confirmFun () {
      const reason = this.reason
      if(!reason) {
        return this.$message.warning('请输入驳回理由')
      }
      this.$api.checkapi.rejectMerchantScreenApi({ id: this.id, reason }).then(() => {
        this.actionAfterFun('驳回成功')
      })
    },
    actionAfterFun (tipstr = '操作成功') {
      this.$message.success(tipstr)
      this.cancelFun()
    },
    previewFun ({ type, url, id }) { // 预览
      if(type === 2) {
        this.viedoUrl = url
      }else {
        const urls = (this.info.screenShopResources || []).filter(e => e.type === 1).map(e => e.url)
        const idx = urls.findIndex(e => e === url)
        this.$viewerApi({
          options: {
							initialViewIndex: idx,
						},
						images: urls
        })
      }
    },
    closeViedo () {
      this.viedoUrl = ''
    },
    getNodeDetail () {
      this.$api.checkapi.getMechantScreenNodeApi({ id: this.id }).then((res) => {
        this.orderFlow = res.data
      })
    },
    getDetail () {
      this.$api.checkapi.getMechantScreenDetailApi({ id: this.id }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/pages/style/detail.scss";
.detail-wrapper {
  display: flex;
  flex-direction: row !important;
  .left,.right {
    flex: 1;
    overflow-y: auto;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
  }
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .item {
    padding: 20px 0;
    display: flex;
    > span:first-of-type {
      flex-shrink: 0;
      width: 100px;
      text-align: left;
    }
  }
  .medias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    .media_wrap {
      position: relative;
      height: 200px;
    }
    .video_tip {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 40px;
      height: 40px;
      transform: translate3d(-50%, -50%, 0)
    };
    .meida_box {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>