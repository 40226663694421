<template>
  <div class="merchantScreenCaste" v-if="$route.path==='/checkModule/check/merchant'">
    <d-search :searchData="searchData"
            @searchEvent="searchEvent"
            @resetEvent="resetEvent" />
    <d-lable :tableData='tableData'
            :columnData='columnData'>
      <template v-slot:status="{ row }">
        <span>{{ $map.screenStatus[row.status] }}</span>
      </template>      
      <template v-slot:action="{ row }">
        <div class="operateClass clickstyle">
          <span @click="actionFun(row, 1)">查看</span>
          <span v-if="row.status === 2" @click="actionFun(row)">审核</span>
        </div>
      </template>      
    </d-lable>
    <d-paging :pager="pager"
            @change="getList" />
  </div>
  <router-view v-else/>
</template>

<script>
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
export default {
  name: 'MerchantScreenCaste',
  components: {
    DSearch,
    DLable,
    DPaging
  },
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      searchData: {
        searchItem: [{
          label: '选择店铺',
          value: 'shopIds',
          type: 'linkageSelect',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'id',
            label: 'name',
            children: 'childList'
          }
        }, {
          label: '状态',
          value: 'status',
          type: 'commonSelect',
          placeholder: '',
          options: [
          {
            label: '已通过',
            value: 4
          },
          {
            label: '待审核',
            value: 2
          },
          {
            label: '已撤销',
            value: 1
          },
          {
            label: '已驳回',
            value: 3
          }, 
          ],
        },{
            label:'提交日期',
            value:'date',
            type:'commonDatetime',
            startPlaceholder:"开始日期",
            endPlaceholder:"结束日期" 
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          shopIds: []
        }
      },
      columnData: [
        { prop: 'id', label: '编号' },
        { prop: 'floorName', label: '楼层' },
        { prop: 'shopCode', label: '店铺号', width: '120' },
        { prop: 'shopName', label: '店铺名称', width: '120' },
        { prop: 'status', label: '状态', slotProp: true },
        { prop: 'createUser', label: '提交人' },
        { prop: 'createTime', label: '提交时间', width: '200' },
        { prop: 'action', label: '操作', slotProp: true }
      ],
      tableData: []
    }
  },
  watch: {
    '$route'(to,from){
      if(to.path==='/checkModule/check/merchant'){
        this.pager.page = parseInt(this.$route.query.page) || 1
        this.getList()
      }
    }
  },
  created () {
    this.getShopList()
    this.getList()
  },
  methods: {
    /**
     * 查询点击
     */
    searchEvent () {
      this.pager.page = 1
      this.pager.rows = 10
      this.getList()
    },
    resetEvent () {
      this.pager.page = 1;
      this.pager.rows = 10;
      this.getList()
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.check.getFloorShop()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    actionFun (column, type) {
      this.$router.push({ path: '/checkModule/check/merchant/check', query: { id: column.id, page: this.pager.page, type } })
    },
    getList () {
      const { date, shopIds, status } = this.searchData.params
      const [start, end] = date || []
      const data = {
        beginCreateTime: start,
        endCreateTime: end,
        shopIds: shopIds && shopIds.length > 0 ? shopIds[shopIds.length - 1] : null,
        status,
        pageNum: this.pager.page,
        pageSize: this.pager.rows
      }
      this.$api.checkapi.getMerchantScreenApi(data).then(({ data }) => {
          this.tableData = data.list || []
          this.pager.count = data.total || 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.merchantScreenCaste {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
    padding: 0 20px;
  }
  .d-paging {
    margin-top: 50px;
    text-align: center;
  }
  .clickstyle {
    color: #5c6be8;
    cursor: pointer;
  }
  .operateClass span {
    display: inline-block;
    margin: 0 5px;
    text-decoration: underline;
  }
  .operateClass span:first-child {
    margin-left: 0;
  }
}
</style>