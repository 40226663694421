<template>
  <div class="reportMarket"
       v-loading="loading">
    <d-search :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent" />
    <d-lable :tableData='tableData'
             :columnData='columnData'
             @checkTime='checkTime'></d-lable>
    <d-paging :pager="pager"
              @change="getItemList" />
  </div>
</template>

<script>

import DSearch from '../../../components/d-search'
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
import { options } from '../../../components/options'
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      loading: true,
      searchData: {
        searchItem: [{
          label: '选择店铺',
          value: 'shopIdList',
          type: 'linkageSelect',
          dateType: 'month',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'name',
            label: 'name',
            children: 'childList'
          }
        }, {
          label: '状态',
          value: 'status',
          type: 'commonSelect',
          placeholder: '',
          options: [],
        }, {
          label: '提交日期',
          value: 'commitTime',
          type: 'commonDate',
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期"
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {}
      },
      columnData: [
        { prop: 'id', label: '编号' },
        { prop: 'floor', label: '楼层' },
        { prop: 'shopNo', label: '店铺号' },
        { prop: 'shopName', label: '店铺名称' },
        { prop: 'outValidity', label: '出门有效期', width: 90 },
        { prop: 'status', label: '状态' },
        { prop: 'commitUser', label: '提交人' },
        { prop: 'createTime', label: '提交时间', width: '200' },
        {
          prop: 'operate', label: '操作', type: {
            check: true
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['checkcache'])
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    ...mapActions(['setOutAllow']),
    /**
     * 审核
     */
    checkTime (column) {
      this.$router.push({ path: '/checkModule/outAllowCheck', query: { id: column.id, page: this.pager.page } })
      this.setOutAllow({
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        commitTime: this.searchData.params.commitTime,
        shop: this.searchData.params.shopIdList,
      })
    },
    /**
     * 翻页查询请求
     */
    getItemList () {
      this.accessPermissionList()
    },
    /**
     * 查询
     */
    searchEvent () {
      this.accessPermissionList()
    },
    /**
     * 重置
     */
    resetEvent () {
      this.tableData = []
      this.pager.pageNum = 1,
        this.pager.pageSize = 10,
        this.pager.count = 0
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * 出入许可列表
     */
    accessPermissionList (shop) {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status,
        createTime: this.searchData.params.commitTime,
        shop: this.searchData.params.shopIdList && this.searchData.params.shopIdList.length > 0 ? this.searchData.params.shopIdList[this.searchData.params.shopIdList.length - 1] : null,
      }
      this.$api.checkapi.accessPermissionList(shop || obj)
        .then(({ code, data, msg }) => {
          this.loading = false
          if (code === 200) {
            this.pager.count = data.total
            this.setOutAllow({})
            this.tableData = data.list.map((item) => ({
              ...item,
              sex: item.sex === 'F' ? '女性' : item.sex === 'M' ? '男性' : '无',
              status: item.status === 0 ? '驳回' : item.status === 1 ? '通过' : item.status === 2 ? '待审核' : '错误状态'
            }))
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 字段表查询
     */
    getSysDict () {
      const obj = {
        dictType: 'audit_status'
      }
      this.$api.checkapi.getSysDict(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            const child = data.list.map((item) => ({
              ...item,
              label: item.dictLabel,
              value: item.dictValue
            }))
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'commonSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * 初始化
     */
    init () {
      this.pager.page = parseInt(this.$route.query.page) || 0
      this.getShopList()
      this.accessPermissionList()
      this.getSysDict()
      if (this.checkcache.outAllow.commitTime || this.checkcache.outAllow.shop || this.checkcache.outAllow.pageNum) {
        this.searchData.params.commitTime = this.checkcache.outAllow.commitTime
        this.searchData.params.shopIdList = this.checkcache.outAllow.shop
        this.pager.page = this.checkcache.outAllow.page,
          this.pager.rows = this.checkcache.outAllow.rows
        this.checkcache.outAllow.shop = this.checkcache.outAllow.shop ? this.checkcache.outAllow.shop[this.checkcache.outAllow.shop.length - 1] : null
        this.checkcache.outAllow.createTime = this.checkcache.outAllow.commitTime
        this.accessPermissionList(this.checkcache.outAllow)
      }
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
    padding: 0 20px;
  }
  .d-paging {
    margin-top: 50px;
    text-align: center;
  }
}
</style>  
