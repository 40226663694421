<template>
  <div class="reportMarket" v-if="$route.path==='/checkModule/check/realname'">
    <d-search :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent" />
    <d-lable :tableData='tableData'
             :columnData='columnData'
             @checkTime='checkTime' @viewEvent="checkTime"></d-lable>
    <d-paging :pager="pager"
              @change="getItemList" />
  </div>
  <router-view v-else/>
</template>

<script>

import DSearch from '../../../components/d-search'
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      // options,
      searchData: {
        searchItem: [
          {
            label: '姓名',
            value: 'name',
            type: 'commonInput',
            placeholder: '请输入姓名'
          },
          {
          label: '选择店铺',
          value: 'shopIdList',
          type: 'linkageSelect',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'name',
            label: 'name',
            expandTrigger: 'hover',
            children: 'childList'
          }
        }, {
          label: '状态',
          value: 'status',
          type: 'commonSelect',
          placeholder: '',
          options: [],
        }, {
          label: '提交日期',
          value: 'commitTime',
          type: 'commonDate',
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期"
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          shopIdList:[],
          status:'',
          name: '',
          commitTime:''
        }
      },
      columnData: [
        { prop: 'name', label: '姓名' },
        { prop: 'sex', label: '性别' },
        { prop: 'idCard', label: '身份证' },
        { prop: 'shopNo', label: '店铺号' },
        { prop: 'shopName', label: '店铺名称' },
        { prop: 'status', label: '状态' },
        { prop: 'commitUser', label: '提交人' },
        { prop: 'updateTime', label: '提交时间' },
        {
          prop: 'operate', label: '操作', type: {
            check: true,
            viewBtn:true
          }
        }
      ],
      tableData: []
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    checkTime (column) {
      this.$router.push({ path: '/checkModule/check/realname/check', query: { id: column.id, page: this.pager.page } })
    },
    // 查询请求
    getItemList () {
      this.realnameList()
    },
    /**
     * 查询
     */
    searchEvent () {
      this.realnameList()
    },
    /**
     * 重置
     */
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.pager.count = 0;
      this.searchData.params.shopIdList=[];
      this.searchData.params.status='';
      this.searchData.params.name='';
      this.searchData.params.commitTime='';
      this.realnameList()
    },
    /**
     * 实名认证列表
     */
    realnameList (shop) {
      // console.log(this.searchData.params.shopIdList)
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status,
        name: this.searchData.params.name,
        updateTime: this.searchData.params.commitTime,
        // createTime: this.searchData.params.commitTime,
        shop: this.searchData.params.shopIdList && this.searchData.params.shopIdList.length > 0 ? this.searchData.params.shopIdList[this.searchData.params.shopIdList.length - 1] : null,
      }
      this.$api.checkapi.realnameList(shop || obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.pager.count = data.total
            this.tableData = data.list.map((item) => ({
              ...item,
              sex: item.sex === 'F' ? '女性' : item.sex === 'M' ? '男性' : '无',
              status: item.status === 0 ? '驳回' : item.status === 1 ? '通过' : item.status === 2 ? '待审核' : '错误状态',
              isCheck: item.status === 2,
              isAccredit: item.status !== 2
            }))
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * 字段表查询
     */
    getSysDict () {
      const obj = {
        dictType: 'audit_status'
      }
      this.$api.checkapi.getSysDict(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            const child = data.list.map((item) => ({
              ...item,
              label: item.dictLabel,
              value: item.dictValue
            }))
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'commonSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * init 初始化
     */
    init () {
      this.pager.page = parseInt(this.$route.query.page) || 1
      this.getShopList()
      this.realnameList()
      this.getSysDict()
    }
  },
  watch: {
    '$route'(to,from){
      if(to.path==='/checkModule/check/realname'){
        this.init()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
    padding: 20px;
  }
  .d-paging {
    background-color: #FFF;
    text-align: center;
  }
}
</style>  
