<template>
  <div class="reportMarket" v-if="$route.path==='/checkModule/check/reportMarket'">
    <d-search :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent" />
    <div style="position:relative">
      <el-tabs v-model="activeTab" @tab-click="handleClick" style="padding: 0 20px;">
        <el-tab-pane :label="item.label" :name="item.value" v-for="item in tabs" :key="item.value"></el-tab-pane>
      </el-tabs>
      <el-button class="loadBtn" type="text" @click="downloadFun">导出</el-button>
    </div>
    <d-lable :tableData='tableData'
             :columnData='columnData'
             >
             <template #action="{ row }">
              <template v-if="activeTab == 0">
                <span class="mgr10 blue" type="text" @click="checkTime(row)" v-if="!((activeTab == 1 && row.status == 1) || (activeTab == 0 && row.status == 2))">查看</span> 
                <span class="mgr10 blue" @click="checkTime(row)" v-else>审核</span> 
              </template>
              <template v-else>
                <span class="mgr10 blue" type="text" @click="checkTime(row, 1)" v-if="row.id">查看</span> 
              </template>
             </template>
    </d-lable>
    <d-paging :pager="pager"
              @change="getItemList" />
  </div>
  <router-view v-else/>

</template>

<script>

import DSearch from '../../../components/d-search'
import DLable from '../../../components/d-table'

import DPaging from '../../../components/d-paging'
import { onDownload, filterDayDate, disabledDayDate } from "@/utils/utils";
import { mapState, mapActions } from 'vuex'
const saleStatusItem = {
  label: '销售状态',
  value: 'type',
  type: 'commonSelect',
  placeholder: '',
  options: [
    { label: '销售', value: 1 },
    { label: '退货', value: 2 }
  ],
}
const saleColumn1 = [
  { prop: 'id', label: '编号' },
  { prop: 'floor', label: '楼层' },
  { prop: 'shopNo', label: '店铺号' },
  { prop: 'shopName', label: '店铺名称' },
  { prop: 'saleTime', label: '销售日期' },
  { prop: 'typeText', label: '销售状态' },
  { prop: 'statusText', label: '状态' },
  { prop: 'commitUser', label: '提交人' },
  { prop: 'submitTime', label: '提交时间' },
  { prop: 'action', label: '操作', slotProp: true }
]
const saleColumn2 = [
  { prop: 'id', label: '编号' },
  { prop: 'floor', label: '楼层' },
  { prop: 'shopNo', label: '店铺号' },
  { prop: 'shopName', label: '店铺名称' },
  { prop: 'saleTime', label: '销售日期' },
  { prop: 'statusName', label: '状态',  },
  { prop: 'commitUser', label: '提交人' },
  { prop: 'submitTime', label: '提交时间' },
  { prop: 'action', label: '操作', slotProp: true }
]
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      maxDate: null,
      minDate: null,
      searchData: {
        searchItem: [{
          label: '选择店铺',
          value: 'shopIdList',
          type: 'linkageSelect',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'name',
            label: 'name',
            children: 'childList'
          }
        }, {
          label: '状态',
          value: 'status',
          type: 'commonSelect',
          placeholder: '',
          options: [],
        }, {
          label: '提交日期',
          value: 'commitTime',
          type: 'commonDate',
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期"
        },{
          label: '销售日期',
          value: 'saleDate',
          type: 'scopeDate',
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
          options: {
            onPick: filterDayDate.bind(this),
            disabledDate: disabledDayDate.call(this, 365*24*60*60*1000)
          }
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          shopIdList:[],
          status:'',
          commitTime:'',
          saleDate: []
        }
      },
      activeTab: '0',
      tabs: [
        { label: '上报销售审核', value: '0' },
        { label: '上报销售统计', value: '1' }
      ],
      saleCheckOptions: [],
      saleStatsticsOptions: [
        { label: '已上报', value: 2 },
        { label: '未上报', value: 1 }
      ],
      columnData: saleColumn1,
      tableData: []
    }
  },
  computed: {
    ...mapState(['checkcache'])
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.setInitDate()
    this.init()
  },
  methods: {
    ...mapActions(['setRreportSale']),
    setInitDate () {
      const date = new Date()
      const endDate = this.$reconava.parseTime(date, '{y}-{m}-{d}')
      const startDate = this.$reconava.parseTime(date - 365*24*60*60*1000, '{y}-{m}-{d}')
      this.searchData.params.saleDate = [startDate, endDate]
    },
    initDataConfig () {
      this.searchData.params.status = null
      this.searchData.searchItem[1].options = this.activeTab == 0 ? this.saleCheckOptions : this.saleStatsticsOptions
      const len = this.searchData.searchItem.length
      if(this.activeTab == 0 && len === 4)  {
        this.searchData.searchItem.push(saleStatusItem)
      }
      if(this.activeTab == 1 && len === 5) {
        this.searchData.searchItem.pop()
      }
      this.columnData = this.activeTab == 0 ? saleColumn1 : saleColumn2
    },
    handleClick () {
      this.initDataConfig()
      this.getItemList()
    },
    downloadFun () {
      const downApi = this.activeTab == 1 ? this.$api.checkapi.downloadSaleStatisticsApi : this.$api.mentapi.reportSaleExport
      const [startSaleTime, endSaleTime] = this.searchData.params.saleDate || []
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status || null,
        submitTime: this.searchData.params.commitTime || null,
        shop: this.searchData.params.shopIdList && this.searchData.params.shopIdList.length > 0 ? this.searchData.params.shopIdList[this.searchData.params.shopIdList.length - 1] : null,
        beginSaleTime: startSaleTime || null,
        endSaleTime: endSaleTime || null,
        type: this.searchData.params.type || null
      }
      downApi(obj).then(res => {
        let { headers, data } = res;
        onDownload(headers, data);
      })
    },
    // 审核
    checkTime (column, n) {
      this.$router.push({ path: '/checkModule/check/reportMarket/check', query: { id: column.id, page: this.pager.page, type: this.activeTab } })
      this.setRreportSale({
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        commitTime: this.searchData.params.commitTime,
        shop: this.searchData.params.shopIdList,
        status:this.searchData.params.status
      })
    },


    // 查询请求
    getItemList () {
      this.reportSaleList()
    },
    /**
     * 查询
     */
    searchEvent () {
      this.reportSaleList()
    },
    /**
     * 重置
     */
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.searchData.params.shopIdList=[];
      this.searchData.params.status='';
      this.searchData.params.commitTime='';
      this.searchData.params.type = ''
      this.setInitDate()
      this.reportSaleList()
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * 上报销售列表
     */
    reportSaleList (shop) {
      const [startSaleTime, endSaleTime] = this.searchData.params.saleDate || []
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status || null,
        submitTime: this.searchData.params.commitTime || null,
        shop: this.searchData.params.shopIdList && this.searchData.params.shopIdList.length > 0 ? this.searchData.params.shopIdList[this.searchData.params.shopIdList.length - 1] : null,
        beginSaleTime: startSaleTime || null,
        endSaleTime: endSaleTime || null,
        type: this.searchData.params.type || null
      }
      const API = this.activeTab == 1 ? this.$api.checkapi.getSaleStatisticsListApi : this.$api.checkapi.reportSaleList
      API(shop || obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.pager.count = data.total
            this.setRreportSale({})
            this.tableData = data.list.map((item) => ({
              ...item,
              sex: item.sex === 'F' ? '女性' : item.sex === 'M' ? '男性' : '无',
              statusText: item.status === 0 ? '驳回' : item.status === 1 ? '通过' : item.status === 2 ? '待审核' : '撤销',
              isCheck: item.status === 2,
              isAccredit: item.status !== 2,
              saleTime: item.saleTime + ' ' + (item.period || '').slice(0, 5)
            }))
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 字段表查询
     */
    getSysDict () {
      const obj = {
        dictType: 'audit_status'
      }
      this.$api.checkapi.getSysDict(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            const child = data.list.map((item) => ({
              ...item,
              label: item.dictLabel,
              value: item.dictValue
            }))
            this.searchData.searchItem.forEach(ele => {
              if (ele.value === 'status') {
                this.saleCheckOptions = child
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * 初始化
     */
    init () {
      this.pager.page = parseInt(this.$route.query.page) || 1
      this.getShopList()
      this.initDataConfig()
      this.activeTab == 0 && this.getSysDict()
      this.searchData.params.commitTime = this.checkcache.reportSale.commitTime ||'';
      this.searchData.params.shopIdList = this.checkcache.reportSale.shop||[];
      this.searchData.params.status = this.checkcache.reportSale.status;
      this.pager.page = this.checkcache.reportSale.page;
      this.pager.rows = this.checkcache.reportSale.rows;
      this.reportSaleList()
    },

  },
  watch: {
    '$route'(to,from){
      if(to.path==='/checkModule/check/reportMarket'){
        this.init()
      }else {
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .loadBtn {
    position: absolute;
    top: 6px;
    right: 20px;
    font-size: 16px;
  }
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
    padding: 0 20px;
  }
  .d-paging {
    margin-top: 50px;
    text-align: center;
  }
}
</style>  
